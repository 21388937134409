.testimonials-section {
  padding: 3rem 1rem;
  background-color: #f8f9fa;
  text-align: center;
}

.testimonials-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.testimonials-section p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;
}

/* Estilos para modo oscuro */
.dark-mode .testimonials-section h2,
.dark-mode .testimonials-section p {
  color: #ffffff;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  justify-content: center;
}

.testimonial-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Placeholder para imagen de perfil */
.image-placeholder {
  width: 80px;
  height: 80px;
  background-color: #e0e0e0; /* Color de fondo del placeholder */
  border-radius: 50%;
  margin-bottom: 1rem;
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 1rem;
  object-fit: cover;
}

.testimonial-card h3 {
  font-size: 1.5rem;
  color: #333;
  margin: 0.5rem 0;
}

.testimonial-role {
  font-size: 1rem;
  color: #888;
  margin-bottom: 1rem;
}

/* Color de feedback en el testimonial */
.testimonial-feedback {
  font-size: 1rem;
  color: #666;
  font-style: italic;
}

/* Estilos en modo oscuro */
.dark-mode .testimonial-card {
  background-color: #444;
  color: #ffffff;
}

.dark-mode .testimonial-card h3,
.dark-mode .testimonial-role {
  color: #ffffff;
}

.dark-mode .testimonial-feedback {
  color: #dddddd;
}

.dark-mode .image-placeholder {
  background-color: #666; /* Placeholder en modo oscuro */
}
