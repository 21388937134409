/* src/index.css */

/* Fondo global y configuración de la fuente */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif; /* Aplica Poppins como fuente global */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background: linear-gradient(to bottom, #ffeb99, #ffcc66); /* Fondo gradiente */
  color: #333;
}

/* Estilo para el código */
code {
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
}
