/* Fondo gradiente y configuración de fuente global */
body {
  background: linear-gradient(to bottom, #F0F4F8, #D9E4EC);
  font-family: 'Poppins', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

/* Contenedor principal para la aplicación */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Estilos del modo oscuro */
.dark-mode {
  background-color: #121212;
  color: #E0E0E0;
}

.dark-mode .header {
  background-color: #333;
}

.dark-mode h1, .dark-mode p, .dark-mode a {
  color: #E0E0E0;
}

.dark-mode .full-screen-section, .dark-mode section {
  background-color: #333;
  border-color: #444;
}

/* Botón para cambiar de tema */
.theme-toggle {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: #FFD700;
  cursor: pointer;
  z-index: 10;
  transition: color 0.3s ease;
}

.theme-toggle:hover {
  color: #FFA500;
}

/* Ajuste en el espaciado entre el círculo y la tarjeta */
.full-screen-section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F0F4F8;
  overflow: hidden;
  border-bottom: 2px solid #D9E4EC;
  padding: 73px 20px;
  width: 100%;
  box-sizing: border-box;
  gap: 30px; /* Aumenta ligeramente el espacio entre el círculo y el encabezado */
}
