.skills-section {
    padding: 3rem 5rem;
    background-color: #f8f9fa;
    text-align: center;
}

.skills-section h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
}

.skills-section p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 2rem;
}

/* Estilos para modo oscuro */
.dark-mode .skills-section h2,
.dark-mode .skills-section p {
    color: #ffffff; /* Cambia el color a blanco en modo oscuro */
}

.skills-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    justify-content: center;
}

.skill-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skill-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.skill-icon {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.skill-card h3 {
    font-size: 1.5rem;
    color: #333;
    margin: 0;
}

.documentation-btn {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
}

.documentation-btn:hover {
    background-color: #0056b3;
}

/* Media query para tabletas */
@media (min-width: 768px) {
    .skills-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* Media query para PC */
@media (min-width: 1024px) {
    .skills-section {
        padding: 3rem 10rem;
    }
    .skills-grid {
        grid-template-columns: repeat(5, 1fr);
    }
}
