.blog-section {
  padding: 3rem 1rem;
  background-color: #f8f9fa;
  text-align: center;
}

.blog-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.blog-section p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;
}

/* Estilos en modo oscuro para título y descripción */
.dark-mode .blog-section h2,
.dark-mode .blog-section p {
  color: #ffffff; /* Cambia a blanco en modo oscuro */
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  justify-content: center;
}

.blog-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
}

.blog-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-card h3 {
  font-size: 1.5rem;
  color: #333;
  padding: 1rem;
  margin: 0;
}

.blog-date {
  font-size: 0.9rem;
  color: #888;
  padding: 0 1rem;
}

.blog-card p {
  font-size: 1rem;
  color: #666;
  padding: 0 1rem 1rem;
}

.blog-link {
  display: inline-block;
  margin: 0 1rem 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.blog-link:hover {
  background-color: #0056b3;
}

/* Media query para pantallas grandes (PC: 1024px o más) */
@media (min-width: 1024px) {
  .blog-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.external-link-btn {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
}

.external-link-btn:hover {
  background-color: #0056b3;
}

/* Modo oscuro para tarjetas de blog */
.dark-mode .blog-card {
  background-color: #ffffff;
  color: #333333;
}

.dark-mode .blog-card h3 {
  color: #333333;
}

.dark-mode .blog-card p,
.dark-mode .blog-date {
  color: #666666;
}

/* Modo oscuro para el contenido del modal */
.dark-mode .modal-image {
  filter: brightness(0.8); /* Oscurece la imagen en modo oscuro si es necesario */
}

.dark-mode .modal h2,
.dark-mode .modal p,
.dark-mode .modal .blog-date {
  color: #ffffff; /* Texto en blanco en el modal en modo oscuro */
}
