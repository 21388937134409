/* src/components/ZoomModal.css */
.zoom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000; /* Asegura que esté encima del modal principal */
  }
  
  .zoom-modal-content {
    position: relative;
    background: #fff;
    padding: 1rem;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .zoom-modal-image {
    max-width: 100%;
    max-height: 80vh;
    border-radius: 8px;
  }
  
  .close-zoom {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 2.5rem; /* Tamaño de fuente aumentado */
    cursor: pointer;
    color: #ff0000; /* Color rojo para mayor visibilidad */
    font-weight: bold;
  }
  