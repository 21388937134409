/* Ajustes generales */
.header {
  width: 100vw;
  background-color: #ffffff;
  padding: 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
}

.header-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #2A3D45;
  margin: 0;
}

.nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

.nav-bar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.nav-bar a {
  color: #2A3D45;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  border-radius: 8px;
  transition: transform 0.2s ease, background-color 0.3s ease, color 0.3s ease;
}

.nav-bar a:hover {
  transform: scale(1.1);
  background-color: #E0E0E0;
  color: #1E90FF;
}

/* Botón de alternancia de tema */
.theme-toggle {
  position: relative;
  left: -20px;
  top: 5px;
  background-color: transparent;
  border: none;
  font-size: 1.8rem;
  color: #FFD700;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

/* Botón de menú (hamburguesa) */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.8rem;
  color: #333;
  cursor: pointer;
}

.menu-toggle.dark-mode-button {
  color: #ffffff;
}

/* Estilo de modo oscuro para PC */
.nav-bar.dark-mode-nav {
  background-color: #333333;
}

.nav-bar.dark-mode-nav a {
  color: #ffffff;
}

/* Media query para pantallas medianas (por debajo de 768px) */
@media (max-width: 768px) {
  .header-title {
    font-size: 1.5rem;
  }

  .menu-toggle {
    display: block;
    position: absolute;
    top: 15px;
    right: 20px;
  }

  .nav-bar {
    display: none;
    flex-direction: column;
    gap: 10px;
    background-color: #ffffff;
    position: absolute;
    top: 60px;
    width: 100%;
    left: 0;
    padding: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .nav-bar.open {
    display: flex;
  }

  /* Ajuste de color oscuro para dispositivos móviles */
  .nav-bar.dark-mode-nav {
    background-color: #ffffff;
  }

  .nav-bar.dark-mode-nav a {
    color: #333333;
  }
}
