/* Contact.css */

.contact-section {
    padding: 3rem 15rem; /* Aumenta el padding lateral */
    background-color: #f8f9fa;
    text-align: center;
    color: #333;
}

.contact-section h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
}

.contact-section p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 2rem;
}

.contact-links {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.contact-link {
    font-size: 1.1rem;
    color: #007bff;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #007bff;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.contact-link:hover {
    background-color: #007bff;
    color: #ffffff;
}

.contact-form {
    max-width: 800px; /* Aumenta el ancho máximo de la forma en pantallas grandes */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact-form input,
.contact-form textarea {
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
}

.contact-form button {
    padding: 0.75rem;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #0056b3;
}

/* Media query para pantallas grandes (PC: 1440px o más) */
@media (min-width: 1440px) {
    .contact-section {
        padding: 3rem 20rem; /* Aumenta aún más el padding lateral en pantallas muy grandes */
    }

    .contact-form {
        max-width: 90%; /* Expande la forma de contacto en pantallas grandes */
    }
}

/* Ajuste para pantallas medianas (tablets y móviles) */
@media (max-width: 768px) {
    .contact-links {
        flex-direction: column;
        gap: 1rem;
    }
    
    .contact-section {
        padding: 2rem 1rem; /* Reduce el padding lateral en dispositivos más pequeños */
    }
}
/* Modo oscuro para el título y la descripción en la sección de contacto */
.dark-mode .contact-section h2,
.dark-mode .contact-section p {
    color: #ffffff; /* Título y descripción en blanco en modo oscuro */
}
