.recognition-section {
  padding: 3rem 1rem;
  background-color: #f8f9fa;
  text-align: center;
}

.recognition-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.recognition-section p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;
}

/* Estilos para modo oscuro */
.dark-mode .recognition-section h2,
.dark-mode .recognition-section > p {
  color: #ffffff; /* Cambia el color a blanco en modo oscuro para el título y la descripción principal */
}

.recognition-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Tres columnas en escritorio */
  gap: 2rem;
  justify-content: center;
}

.recognition-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.recognition-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.recognition-icon {
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 1rem;
}

.recognition-card h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

/* Mantener las descripciones en color negro en modo oscuro */
.dark-mode .recognition-card p {
  color: #333333;
}

.recognition-card p {
  font-size: 1rem;
  color: #666;
}

.view-more-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-more-btn:hover {
  background-color: #0056b3;
}

/* Media query para tabletas (pantallas medianas) */
@media (max-width: 1024px) {
  .recognition-grid {
    grid-template-columns: repeat(2, 1fr); /* Dos columnas en tabletas */
  }

  .recognition-section h2 {
    font-size: 2rem;
  }
}

/* Media query para móviles (pantallas pequeñas) */
@media (max-width: 768px) {
  .recognition-grid {
    grid-template-columns: 1fr; /* Una columna en móviles */
  }

  .recognition-section {
    padding: 3rem 1rem;
  }

  .recognition-section h2 {
    font-size: 1.8rem;
  }

  .recognition-section p {
    font-size: 1rem;
  }

  .recognition-icon {
    font-size: 2.5rem;
  }

  .recognition-card h3 {
    font-size: 1.3rem;
  }

  .recognition-card p {
    font-size: 0.9rem;
  }
}
