/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    max-height: 80vh; /* Limita la altura máxima para el scroll */
    overflow-y: auto; /* Habilita el scroll vertical */
    position: relative;
    text-align: center;
  }
  
  .modal-body {
    max-height: 70vh;
    overflow-y: auto;
  }
  
  .modal-image {
    width: 100%;
    border-radius: 5px;
    margin-top: 1rem;
  }
  
  .modal-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
  }
/* src/components/Modal.css */
.click-instruction {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem;
    text-align: center;
  }
  