/* Estilos generales */
.contact-buttons {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
}

.contact-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  padding: 10px;
  border-radius: 8px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Colores específicos para cada botón */
.contact-button.whatsapp { background-color: #25D366; }
.contact-button.linkedin { background-color: #0A66C2; }
.contact-button.email { background-color: #D44638; }
.contact-button.github { background-color: #333; }

/* Efecto de hover */
.contact-button:hover {
  opacity: 0.9;
  transform: scale(1.05);
}

/* Modo oscuro */
.dark-mode .contact-button {
  color: white;
}

.dark-mode .contact-button.github {
  background-color: #444;
}

/* Media Query para pantallas de hasta 768px (tabletas) */
@media (max-width: 768px) {
  .contact-button {
      width: 120px; /* Reduce el ancho de los botones */
      padding: 0px; /* Reduce el padding */
      border-radius: 22px; /* Ajusta el borde redondeado */
      font-size: 0.9rem; /* Reduce el tamaño de la fuente */
  }
}

/* Media Query para pantallas de hasta 480px (móviles) */
@media (max-width: 480px) {
  .contact-button {
      width: 82px; /* Reduce aún más el ancho de los botones */
      padding: 0px; /* Reduce el padding */
      border-radius: -1%; /* Convierte los botones en círculos */
      font-size: 0.6rem; /* Ajusta el tamaño de la fuente */
      text-align: center; /* Centra el texto */
      height: 30px; /* Ajusta la altura para que el botón sea circular */
  }
}
