/* Estilo para el fondo */
.background {
    background-image: url('../assets/images/background.jpg'); /* Ruta a la imagen */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: absolute; /* Cambia a relative para que funcione ::before */
    width: 100%;
    min-height: 127vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Evita que el contenido se desborde */
}

.background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Ajusta el nivel de gris y la transparencia */
    z-index: 1.5; /* Coloca esta capa encima del fondo, pero debajo del contenido */
}

/* Ajustes para el contenedor de contenido */
.content {
    position: relative; /* Asegura que esté sobre la capa gris */
    z-index: 2; /* Coloca el contenido en un nivel superior */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: rgba(148, 142, 142, 0.8); /* Fondo semitransparente */
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
    max-width: 90%;
}

/* Estilos para la tarjeta */
.card {
    padding: 20px;
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
}

/* Estilos para el círculo */
.circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #fded04;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
