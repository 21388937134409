/* Footer.css */
.footer {
    background-color: #333;
    color: #f0f0f0;
    padding: 2rem 1rem;
    text-align: center;
    position: relative;
    width: 100%;
    border-top: 2px solid #007bff; /* Añade un borde superior para darle un toque de color */
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .footer-logo h2 {
    font-size: 1.8rem;
    color: #f0f0f0;
    margin-bottom: 0.5rem;
  }
  
  .footer-logo p {
    font-size: 1rem;
    color: #b0b0b0;
    margin: 0;
  }
  
  .footer-socials a {
    font-size: 1.5rem;
    color: #f0f0f0;
    margin: 0 0.5rem;
    transition: color 0.3s ease;
  }
  
  .footer-socials a:hover {
    color: #007bff; /* Color de acento en hover */
  }
  
  .footer-bottom {
    margin-top: 1.5rem;
    font-size: 0.9rem;
    color: #b0b0b0;
  }
  
  .footer-bottom p {
    margin: 0;
  }
  
  /* Modo oscuro para el footer */
  .dark-mode .footer {
    background-color: #222;
    border-top: 2px solid #f0f0f0;
  }
  
  .dark-mode .footer-logo h2,
  .dark-mode .footer-logo p,
  .dark-mode .footer-bottom p {
    color: #f0f0f0;
  }
  
  .dark-mode .footer-socials a {
    color: #f0f0f0;
  }
  
  .dark-mode .footer-socials a:hover {
    color: #ffcc00;
  }
  
  /* Media query para pantallas pequeñas */
  @media (max-width: 768px) {
    .footer-logo h2 {
      font-size: 1.5rem;
    }
    .footer-logo p {
      font-size: 0.9rem;
    }
  }
  