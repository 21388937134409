/* Estilo para la tarjeta de perfil */
#home {
  background-color: #ffffff;
  padding: 2rem;
  text-align: center;
  width: 345px;
  border-left: 20px solid #FF5722;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: #333;
  margin: 162px auto;
  position: relative;
  z-index: 1;
  left: -258px; /* Ajuste para mover la tarjeta hacia la izquierda */
}

.profile-image {
  width: 117px;
  height: 126px;
  border-radius: 50%;
  margin-bottom: 1rem;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: -6rem;
}

#home h1 {
  font-size: 22px;
  color: #333;
  margin-bottom: 1rem;
  font-weight: bold;
}

#home p {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

/* Media query para pantallas más pequeñas (tablets) */
@media (max-width: 768px) {
  #home {
    width: 38%; /* Ajuste del ancho en tablets */
    left: -215px; /* Ajuste de posición */
    padding: 0.5rem;
    margin-top: 15px;
  }

  #home h1 {
    font-size: 20px;
  }

  #home p {
    font-size: 13px;
  }

  .profile-image {
    width: 100px;
    height: 110px;
    margin-top: -6rem;
    border-radius: 40%;
  }
}

/* Media query para pantallas muy pequeñas (móviles) */
@media (max-width: 480px) {
  #home {
    width: 100%;
    left: 3px;
    padding: 0rem;
    margin: px auto;
    top: -3px;
  }

  #home h1 {
    font-size: 18px;
  }

  #home p {
    font-size: 12px;
  }

  .profile-image {
    width: 80px;
    height: 90px;
    margin-top: -2.5rem;
  }
}
.download-cv-button {
  display: inline-block;
  margin-top: 1.5rem;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.download-cv-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.download-cv-button:active {
  transform: scale(0.98);
}
