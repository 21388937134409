/* General Experience Section */
.experience-section {
    padding: 3rem 1rem;
    background-color: #f8f9fa;
    text-align: center;
    overflow: hidden;
}

.experience-section h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
    position: relative;
    animation: fadeIn 1s ease-in-out forwards;
    opacity: 0;
}

.experience-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
    position: relative;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.timeline {
    position: relative;
    margin-left: 20px;
    padding-left: 20px;
    border-left: 3px solid #007bff;
    animation: growLine 2s ease forwards;
}

.experience-item {
    position: relative;
    margin-bottom: 3rem;
    padding-left: 40px;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s ease;
}

.experience-item:before {
    content: '';
    position: absolute;
    left: -20px;
    top: 5px;
    width: 15px;
    height: 15px;
    background-color: #007bff;
    border-radius: 50%;
    border: 3px solid #ffffff;
    transition: transform 0.3s ease;
}

.experience-item h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 0.5rem;
}

.experience-item .date {
    font-size: 1rem;
    color: #666;
    margin-bottom: 0.5rem;
}

.experience-item p {
    font-size: 1rem;
    color: #555;
    background: #e6f2ff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.6s ease, box-shadow 0.6s ease;
}

/* Animaciones */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes growLine {
    0% {
        height: 0;
    }
    100% {
        height: 100%;
    }
}

.experience-item.visible {
    opacity: 1;
    transform: translateY(0);
}

.experience-item.visible p {
    transform: translateY(0);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.experience-item.visible:before {
    transform: scale(1.2);
}

/* Modo oscuro */
.dark-mode .experience-section {
    background-color: #333;
}

.dark-mode .experience-section h2 {
    color: #ffffff;
}

.dark-mode .experience-item h3 {
    color: #000000; /* Títulos en negro en modo oscuro */
}

.dark-mode .experience-item .date {
    color: #000000; /* Fechas en negro en modo oscuro */
}

.dark-mode .experience-item p {
    color: #ffffff;
    background-color: #007bff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

.dark-mode .timeline {
    border-left-color: #007bff;
}

.dark-mode .experience-item:before {
    background-color: #007bff;
}

/* Media Query para pantallas grandes (PC) */
@media (min-width: 1024px) {
    .experience-section {
        padding: 3rem 18rem;
    }
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
    .experience-content {
        padding-left: 10px;
    }

    .experience-item {
        padding-left: 30px;
    }
}
