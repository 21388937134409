@keyframes subtleSwing {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes typingAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.nav-wheel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background-color: #007bff;
  padding: 20px;
  margin-left: 145px; /* Ajuste para centrar el círculo junto a la tarjeta */
}

/* Media query para pantallas grandes (tablets y laptops) */
@media (max-width: 1024px) {
  .nav-wheel {
      width: 500px;
      height: 500px;
      margin-left: 0; /* Centra el círculo */
  }
  .robot {
      width: 200px;
      height: 200px;
  }
  .welcome-message {
      font-size: 11px;
      max-width: 200px;
  }
}

/* Media query para pantallas medianas (tablets en vertical, móviles grandes) */
@media (max-width: 768px) {
  .nav-wheel {
      width: 400px;
      height: 400px;
      padding: 15px;
      margin-left: 71px;
  }
  .robot {
      width: 150px;
      height: 150px;
  }
  .welcome-message {
      font-size: 10px;
      max-width: 180px;
  }
}

/* Media query para pantallas pequeñas (móviles) */
/* Media query para ocultar los cuadros animados en resolución de 468px o menos */
/* Media query para pantallas pequeñas (celulares, resolución de 468px o menos) */
@media (max-width: 468px) {
  .nav-button {
    display: none; /* Oculta los cuadros con texto animado */
  }
  
  .welcome-message {
    display: none; /* Oculta el mensaje de bienvenida */
  }
  
  .nav-wheel {
    width: 250px; /* Ajusta el tamaño del círculo para que solo contenga al robot */
    height: 250px;
    padding: 10px;
    background-color: #f0f0f0; /* Color de fondo para el robot */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; /* Mantiene el círculo */
  }

  .robot {
    width: 100px; /* Ajusta el tamaño del robot */
    height: 100px;
    position: relative;
    left: -174px; /* Ajusta la posición horizontal solo en esta resolución */
    top: 154px; /* Ajusta la posición vertical solo en esta resolución */
  }
}


.rotating-wheel {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: subtleSwing 10s ease-in-out infinite;
}

.outer-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.robot {
  position: absolute;
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.welcome-message {
  position: absolute;
  top: -40px;
  font-size: 13px;
  color: #333;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 15px;
  border-radius: 8px;
  text-align: center;
  max-width: 250px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.welcome-message::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(255, 255, 255, 0.9);
}

.typing-letter {
  display: inline-block;
  opacity: 0;
  animation: typingAnimation 0.5s forwards;
}

.nav-button {
  position: absolute;
  width: 200px;
  height: 50px;
  border-radius: 8px;
  background-color: #333; /* Color de fondo oscuro como una terminal */
  color: #00ff00; /* Color verde como texto de terminal */
  text-align: left; /* Alineación del texto a la izquierda */
  padding: 10px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  border: 2px solid rgba(0, 0, 0, 0.2);
  z-index: 2;
}
/* Animación de máquina de escribir */
/* Animación de máquina de escribir */
@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

.typing-text {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid; /* Línea del cursor */
  animation: typing 3s steps(30, end) forwards; /* Cambia a 3s y agrega forwards para que se detenga al final */
}

/* Elimina la animación de parpadeo del cursor para que no se repita */
@keyframes blinkCursor {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}


/* Ajuste las posiciones de los botones restantes en el círculo */
.nav-button:nth-child(2) { top: 2%; left: 50%; transform: translate(-50%, -50%); }
.nav-button:nth-child(3) { top: 24%; left: 96%; transform: translate(-50%, -50%); }
.nav-button:nth-child(4) { bottom: 35%; right: -27%; transform: translate(-50%, -50%); }
.nav-button:nth-child(5) { bottom: 15%; left: 71%; transform: translate(-50%, 50%); }

/* Ajuste las posiciones de los botones en pantallas más pequeñas */
@media (max-width: 768px) {
  .nav-button:nth-child(2) { top: 5%; left: 50%; transform: translate(-50%, -50%); }
  .nav-button:nth-child(3) { top: 32%; left: 99%; transform: translate(-50%, -50%); }
  .nav-button:nth-child(4) { bottom: 19%; right: -71%; transform: translate(-50%, -50%); }
  .nav-button:nth-child(5) { bottom: 1%; left: 68%; transform: translate(-50%, 50%); }
}

@media (max-width: 480px) {
  .nav-button:nth-child(2) { top: 10%; left: 50%; transform: translate(-50%, -50%); }
  .nav-button:nth-child(3) { top: 35%; left: 80%; transform: translate(-50%, -50%); }
  .nav-button:nth-child(4) { bottom: 25%; right: -15%; transform: translate(-50%, -50%); }
  .nav-button:nth-child(5) { bottom: 5%; left: 55%; transform: translate(-50%, 50%); }
}

/* Modo oscuro para los botones de navegación */
.dark-mode .nav-button {
  color: #00ff00; /* Cambia el color del texto a negro en modo oscuro */
}
